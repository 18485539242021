import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"800","scrollable":"","persistent":"","minHeight":"1200"},model:{value:(_vm.showingFileBatchModal),callback:function ($$v) {_vm.showingFileBatchModal=$$v},expression:"showingFileBatchModal"}},[_c(VForm,{ref:"fileBatchForm",on:{"submit":function($event){$event.preventDefault();return _vm.createFileBatch.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Crear lote de archivos ")]),_c(VCardText,{staticStyle:{"max-height":"500px"}},[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTextField,{ref:"name",attrs:{"rules":[function (v) { return !!v || 'Nombre requerido'; }],"outlined":"","dense":"","autocomplete":"off","hide-details":"","label":"Nombre del lote"},model:{value:(_vm.form_name),callback:function ($$v) {_vm.form_name=$$v},expression:"form_name"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('drag-and-drop',{ref:"drag_and_drop",attrs:{"is-zip-file":true},on:{"files-to-send":_vm.bindFilesToSend}})],1)],1)],1),_c(VDivider),_c(VCardActions,[(_vm.error_msg)?_c('span',{staticClass:"error--text error-msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"primary","type":"submit","loading":_vm.btnLoading}},[_vm._v(" CREAR LOTE ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }