<template>
  <v-dialog
    v-model="showingFileBatchModal"
    width="800"
    scrollable
    persistent
    minHeight="1200"
  >
    <v-form ref="fileBatchForm" @submit.prevent="createFileBatch">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Crear lote de archivos
        </v-card-title>
        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                ref="name"
                v-model="form_name"
                :rules="[(v) => !!v || 'Nombre requerido']"
                outlined
                dense
                autocomplete="off"
                hide-details
                label="Nombre del lote"
              ></v-text-field>
            </v-col>
  
            <v-col cols="12" md="12">
              <drag-and-drop ref="drag_and_drop"
                @files-to-send="bindFilesToSend"
                :is-zip-file="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions>
          <span class="error--text error-msg" v-if="error_msg">{{ error_msg }}</span>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="closeModal()"> CANCELAR </v-btn>

          <v-btn color="primary" type="submit" :loading="btnLoading">
            CREAR LOTE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
const debounce = require('debounce');
import { ref } from "@vue/composition-api";
import { mdiPlusCircleOutline, mdiTrashCanOutline } from "@mdi/js";
import DragAndDrop from "@/components/Messages/DragAndDrop";

export default {
  data: () => {
    return {
      form_name: '',
      form_files: [],
      showingFileBatchModal: false,
      error_msg: null,
      btnLoading: false
    }
  },
  components: {
    DragAndDrop,
  },
  methods: {
    openModal() {
      this.showingFileBatchModal = true
    },

    closeModal() {
      this.$refs.drag_and_drop.reset()
      this.form_name = ''
      this.form_files = []
      this.$emit("reload-files-list")
      this.showingFileBatchModal = false
    },

    async createFileBatch() {
      this.btnLoading = true
      this.error_msg = null
      try {
        let form = {
          name: this.form_name,
          company: this.getSelectedCompany
        }

        const formData = new FormData();
        for (const key in form) {
          formData.set(key, form[key]);
        }

        this.form_files.forEach(el => {
          formData.append('additional_files', el)
        })

        const response = await this.$api.createFileBatch(formData)
        this.$emit("reload-files-list");
        this.closeModal()
      }catch(e) {
        console.log(e)
        if(e?.response?.data?.error) {
          this.error_msg = e.response.data.error
        } else if(e?.response?.data?.message) {
          this.error_msg = e.response.data.message
        } else {
          this.error_msg = 'Ocurrió un error. Por favor intente nuevamente más tarde.'
        }
      } finally {
        this.btnLoading = false
      }
    },

    bindFilesToSend(files) {
      this.form_files = files
    }
  },

  computed: {
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },
}
</script>

