<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear indeterminate color="black" class="mb-0"></v-progress-linear>
      </v-card-text>
      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2">{{
          `Lote: ${batch.name}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- <div>
        <v-card-subtitle class="pt-4 ml-10">
          {{ `Creada en ${"eee"} por el usuario ${"eee"}` }}
        </v-card-subtitle>
      </div> -->

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row class="mt-2">
          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Estado</p>
                <span class="text--primary text-xl font-weight-bold" v-if="batch.status">
                  {{ statusParser[batch.status] }}
                </span>

                <p class="text-s mb-0" v-if="batch.creation_error">Error</p>
                <span  class="text--primary text-xl font-weight-bold"  v-if="batch.creation_error">
                  {{ batch.creation_error }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="warning" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiCalendarQuestion }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Fecha de creación</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ batch.created_at }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar size="44" color="info" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ icons.mdiAccountMultiplePlus }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">Cantidad de archivos</p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ batch.total_files }}
                </span>
              </div>
            </div>
          </v-col>

        </v-row>
      </v-card-text>

      <div>
        <v-card>
          <v-divider class="mt-4"></v-divider>

          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <!-- Search -->
            <v-text-field
            v-model="searchQuery"
            @keyup="handleSearch"
            placeholder="Buscar por nombre"
            outlined
            hide-details
            dense
            class="contact-search me-3 mb-4"
            >
            </v-text-field>

            <v-spacer></v-spacer>
          </v-card-text>

          <v-data-table
          :headers="tableColumns"
          :items="filesListTable"
          :page="current_page"
          :server-items-length="totalFilesListTable"
          :loading="filesLoading"
          :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
          @update:page="changingFilesPage($event)"
          @update:items-per-page="changingFilesItemsPerPage($event)"
          >

            <!-- NO data -->
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No hay
                  <strong>archivos</strong>
                  disponibles para esta búsqueda
                </v-list-item-title>
              </v-list-item>
            </template>

            <!-- name -->
            <template #[`item.name`]="{item}">
              <span class="font-weight-bold">{{ item.name }}</span>
            </template>

            <template #[`item.file`]="{item}">
              <v-btn
                icon
                :href="item.file"
                target="_blank"
              >
                <v-icon color="primary">
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-btn>
            </template>

          </v-data-table>

        </v-card>
      </div>

    </v-card>

    <AlertPopUp ref="alert_pop_up" />
  </v-dialog>
</template>
<script>
const debounce = require('debounce');
import { ref } from "@vue/composition-api";
import AlertPopUp from "../AlertPopUp.vue";
import {
  mdiEyeOutline,
  mdiProgressCheck,
  mdiClose,
  mdiHelpCircleOutline,
  mdiCalendarQuestion,
  mdiWhatsapp,
  mdiReloadAlert,
  mdiSendOutline,
  mdiDownload,
  mdiEyePlusOutline,
  mdiPlus,
  mdiFileDownloadOutline,
  mdiFileRemoveOutline,
  mdiAccountMultiplePlus,
  mdiClipboardAlertOutline
} from "@mdi/js";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      current_page: 1,
      page_size: 10,
    };
  },
  methods: {
    async getFileBatchFiles() {
      this.filesLoading = true
      try {
        const query = {
          batch_id: this.batch.id,
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getFileBatchFiles(query)

        this.filesListTable = response.results
        this.totalFilesListTable = response.count

      } catch(error) {
        console.log(error)
      } finally {
        this.filesLoading = false
        //this.setTimer()
      }
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getFileBatchFiles()
    }, 400),

    changingFilesPage(e) {
      this.current_page = e
      this.getFileBatchFiles()
    },

    changingFilesItemsPerPage(e) {
      this.page_size = e
      this.getFileBatchFiles()
    },
    open(item) {
      this.batch = item
      this.getFileBatchFiles()
      this.dialog = true;
    },
    close() {
      this.batch = {};
      this.current_page = 1
      this.dialog = false;
    },

    clickCreatedLink(data, name) {
      const blob = new Blob([data], { type: 'text/html;charset=utf-8;' });
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", name);
      link.click();
    },
  },
  computed: {
    statusParser() {
      const parser = {
        0: "En creación",
        1: "Finalizado",
        2: "Pendiente",
        3: "Error",
      };
      return parser;
    },
  },
  setup() {
    const batch = ref({})

    const totalFilesListTable = ref(1);
    const filesListTable = ref([]);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Descargar', value: 'file', align: 'center', sortable: false },
    ];
    const filesLoading = ref(false);
    const searchQuery = ref('');
    return {
      batch,
      tableColumns,
      filesListTable,
      filesLoading,
      totalFilesListTable,
      searchQuery,
      icons: {
        mdiEyeOutline,
        mdiProgressCheck,
        mdiClose,
        mdiHelpCircleOutline,
        mdiCalendarQuestion,
        mdiWhatsapp,
        mdiReloadAlert,
        mdiSendOutline,
        mdiDownload,
        mdiEyePlusOutline,
        mdiPlus,
        mdiFileDownloadOutline,
        mdiFileRemoveOutline,
        mdiAccountMultiplePlus,
        mdiClipboardAlertOutline
      },
    }
  },
  components: { AlertPopUp },
}
</script>
<style scoped lang="scss">
.tool-bar {
  box-shadow: 0 0px 1px 0px rgba(94, 86, 105, 0.56) !important;
}
</style>