<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar por nombre"
        outlined
        hide-details
        dense
        class="contact-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
          color="primary"
          class="mb-4 me-3 px-2 no-uppercase"
          @click="$refs.create_file_batch.openModal()"
          >
            <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
            <span>Crear lote de archivo</span>
          </v-btn>

        </div>
      </v-card-text>

      <v-data-table
      :headers="tableColumns"
      :items="filesListTable"
      :page="current_page"
      :server-items-length="totalFilesListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingFilesPage($event)"
      @update:items-per-page="changingFilesItemsPerPage($event)"
      >

        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Lotes de archivos</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- name -->
        <template #[`item.name`]="{item}">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.file_batch_detail.open(item)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-btn
            class="no-uppercase status-container pa-2"
            depressed
            :color="statusColorParser(item.status)"
            max-height="26px"
            min-width="0"
            v-if="
              (item.percentage_progress_creation === 0 && item.status !== 0) ||
              item.percentage_progress_creation === 100 || item.status === 1
            "
          >
            <span style="color: white">{{ statusParser[item.status] }}</span>
          </v-btn>
          <div
            v-else
            class="rounded-lg relative"
            style="
              width: 100%;
              background-color: #bdbdbd;
              height: 26px;
              position: relative;
            "
          >
            <div
              class="rounded-lg"
              :style="{
                width: item.percentage_progress_creation + '%',
                'background-color':
                  item.percentage_progress_creation < 25
                    ? '#F44336'
                    : item.percentage_progress_creation >= 25 &&
                      item.percentage_progress_creation <= 50
                    ? '#FF9800'
                    : item.percentage_progress_creation > 50 &&
                      item.percentage_progress_creation <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              }"
            ></div>
            <span
              style="
                position: absolute;
                top: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                color: white;
              "
              >{{ item.percentage_progress_creation }}%</span
            >
          </div>
        </template>

        <template #[`item.delete`]="{item}">
					<v-btn
						icon
            @click="$refs.delete_batch_modal.open(item)"
					>
						<v-icon class="error--text">
							{{ icons.mdiDeleteOutline }}
						</v-icon>
					</v-btn>
        </template>

      </v-data-table>

    </v-card>

    <FileBatchDetail
    ref="file_batch_detail"
    />

    <CreateFileBatch 
    ref="create_file_batch"
    @reload-files-list="getFileBatches()"
    />

    <DeleteFileBatch
    ref="delete_batch_modal"
    @reload-files-list="getFileBatches()"
    @display-alert="showCreationAlert"
    />

    <alert-pop-up ref="alert_pop_up" />
  </div>
</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import AlertPopUp from '@/components/AlertPopUp.vue';
import DeleteFileBatch from './DeleteFileBatch.vue';
import CreateFileBatch from './CreateFileBatch.vue'
import FileBatchDetail from './FileBatchDetail.vue';
import {
  mdiAccountPlus,
  mdiFileUploadOutline,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiTag,
  mdiDownload,
  mdiLogin
} from '@mdi/js'

export default {
  components:{
    AlertPopUp,
    DeleteFileBatch,
    CreateFileBatch,
    FileBatchDetail
},
  data:() => {
    return {
      current_page: 1,
      page_size: 10,
      companyTagsData:[],
      timer: null,
      creatingColor: "rgb(146, 149, 143)",
      completedColor: "rgb(86, 202, 0)",
    }
  },
  mounted() {
    if(this.getSelectedCompany) {
      this.getFileBatches()
    }
  },
  methods: {
    async getFileBatches() {
      this.loading = true
      try {
        const query = {
          company_id: this.getSelectedCompany !== 'all' ? this.getSelectedCompany : '',
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getFileBatches(query)

        this.filesListTable = response
        this.totalFilesListTable = response.length

      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
        //this.setTimer()
      }
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getFileBatches()
    }, 400),

    changingFilesPage(e) {
      this.current_page = e
      this.getFileBatches()
    },

    changingFilesItemsPerPage(e) {
      this.page_size = e
      this.getFileBatches()
    },

    // setTimer() {
    //   if (this.timer) {
    //     clearTimeout(this.timer)
    //   }

    //   this.timer = setTimeout(() => {
    //     this.getFileBatches()
    //   }, this.filesListTable.some(el => el.status.id === 0 || el.google_status.id === 1) ? 10000 : 6000000 )
    // },

    statusColorParser(status) {
      if (status === 0) return this.creatingColor;
      if (status === 1) return this.completedColor;
      if (status === 2) return this.creatingColor;
    },

    showCreationAlert(data) {
      this.$refs.alert_pop_up.showPopUp({ message: data.message, type: data.type })
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
    statusParser() {
      const parser = {
        0: "En creación",
        1: "Finalizado",
        2: "Pendiente",
        3: "Error",
      };
      return parser;
    },
  },
  watch: {
    getSelectedCompany(newValue) {
      if(newValue) {
        this.current_page = 1
        this.page_size = 10
        this.getFileBatches()
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  setup () {
    const totalFilesListTable = ref(1);
    const filesListTable = ref([]);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Cantidad de archivos', value: 'total_files', align: 'center', sortable: false },
      { text: 'Fecha y hora de creación', value: 'created_at', align: 'center', sortable: false },
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
    ];
    const loading = ref(false);
    const searchQuery = ref('');

    return {
      tableColumns,
      loading,
      searchQuery,
      totalFilesListTable,
      filesListTable,
      icons: {
        mdiAccountPlus,
        mdiFileUploadOutline,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiTag,
        mdiDownload,
        mdiLogin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-uppercase {
      text-transform: unset !important;
}

.tag-container {
    color: white;
    background-color: rgb(120, 120, 120);
    font-size: 14px;
}

.tag-container:hover {
    cursor: default;
}
</style>
