import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.showingDeleteModal),callback:function ($$v) {_vm.showingDeleteModal=$$v},expression:"showingDeleteModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" ¿Estás seguro de eliminar el lote '"+_vm._s(_vm.batch.name)+"'? ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"error"},on:{"click":function($event){return _vm.deleteBatch()}}},[_vm._v(" Eliminar ")])],1),_c('alert-pop-up',{ref:"alert_pop_up"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }