<template>
  <v-dialog
      v-model="showingDeleteModal"
      width="auto"
  >
      <v-card>
          <v-card-title class="text-h5 grey lighten-2">
              ¿Estás seguro de eliminar el lote '{{ batch.name }}'?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
              color="primary"
              text
              @click="close()"
              >
                  CANCELAR
              </v-btn>
              <v-btn
              :loading="loading"
              color="error"
              @click="deleteBatch()"
              >
                  Eliminar
              </v-btn>
          </v-card-actions>
          <alert-pop-up ref="alert_pop_up" />
      </v-card>
  </v-dialog>
</template>

<script>
import AlertPopUp  from '@/components/AlertPopUp.vue';

export default {
  data: () => {
      return {
          showingDeleteModal: false,
          batch: {},
          loading: false
      }
  },
  components: {
      AlertPopUp
  },

  methods: {
      open(batch) {
          this.showingDeleteModal = true
          this.batch = batch
      },

      close() {
          this.$emit('reload-files-list')
          this.showingDeleteModal = false
      },

      async deleteBatch() {
        this.loading = true
          try {
              const response = await this.$api.deleteFileBatch(this.batch.id)

              this.$emit("display-alert", { message: 'El lote se eliminó correctamente', type: 'success' });
              this.close()
          } catch(e) {
              console.log(e)
              if(e?.response?.status === 403) {
                  this.$refs.alert_pop_up.showPopUp({ message: e?.response?.data?.error, type: 'error' })
              }else {
                this.$refs.alert_pop_up.showPopUp({ message: 'Ocurrió un error. Por favor intente nuevamente más tarde.', type: 'error' })
              }
          } finally {
            this.loading = false
          }
      }
  }
}
</script>