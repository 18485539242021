<template>
  <div>
      <v-card class="mb-6">
          <v-card-title>Lotes de archivos</v-card-title>
          <FileBatchesList></FileBatchesList>
      </v-card>
  </div>
</template>

<script>
import FileBatchesList from '@/components/FileBatches/FileBatchesList.vue'

export default {
  components: {
    FileBatchesList
  }
}
</script>
